import { ActionContext, ActionTree } from "vuex";

import ApiServices from "@/core/services/User";

interface State {
  listUser: Array<any>;
}

const state: State = {
  listUser: [],
};

const getters = {
  getListUser: (state: State) => state.listUser,
};

const actions: ActionTree<State, any> = {
  async fetch(context: ActionContext<State, any>, state: State) {
    try {
      const resp = ApiServices.UsersList();
      context.commit("SET_LIST_USERS", resp);
    } catch (error: any) {
      console.log("error", error);
    }
  },

  setUsers: ({ commit }, payload) => {
    commit("SET_LIST_USERS", payload);
  },
};

const mutations = {
  SET_LIST_USERS(state: State, data: Array<any>) {
    state.listUser = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
