import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "element-plus/theme-chalk/display.css";

import * as ElementPlusIconsVue from "@element-plus/icons-vue";

// Libary
import i18n from "@/core/plugins/vue-i18n";

// Style
import "@/assets/style/style.scss";

import createLoadingPlugin from "./core/plugins/loading";

const app = createApp(App);
app.use(router);
app.use(store);
app.use(ElementPlus);

app.use(i18n);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

// Install the loading plugin
app.use(createLoadingPlugin());

app.mount("#app");
