import { ActionContext } from "vuex";

import ApiServices from "@/core/services/Boardcast";

interface State {
  listBoardcast: Array<any>;
}

const state: State = {
  listBoardcast: [],
};

const getters = {
  getListBoardcast: (state: State) => state.listBoardcast,
};

const actions = {
  async fetch(context: ActionContext<State, any>, state: State) {
    try {
      const resp = await ApiServices.ContentList();
      context.commit("SET_LIST_BOARDCAST", resp);
    } catch (error: any) {
      console.log("error", error);
    }
  },
};

const mutations = {
  SET_LIST_BOARDCAST(state: State, data: Array<any>) {
    state.listBoardcast = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
