class Storage {
  async checkToken() {
    let pass = false;
    pass = localStorage.getItem("token") == null ? false : true;
    return pass;
  }

  setToken(token: string) {
    localStorage.setItem("token", token);
  }
  getToken() {
    return localStorage.getItem("token");
  }

  setSound(value: any) {
    localStorage.setItem("sound", value);
  }
  getSound() {
    return localStorage.getItem("sound");
  }

  clearToken() {
    localStorage.removeItem("token");
    // sessionStorage.clear();
  }
}
export default new Storage();
