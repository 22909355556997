import Vuex, { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

import users from "./modules/users.module";
import contents from "./modules/content.module";
import boardcast from "./modules/boardcast.module";

export default createStore({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    users,
    contents,
    boardcast,
  },
});
