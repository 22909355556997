import { createI18n } from "vue-i18n";
import en from "@/assets/locales/en.json";
import th from "@/assets/locales/th.json";

const i18n = createI18n({
  legacy: false,
  locale: "th",
  fallbackLocale: "th",
  globalInjection: true,
  messages: {
    en: en,
    th: th,
  },
});

export default i18n;
