/* eslint-disable @typescript-eslint/no-unused-vars */
import { post, get, postUpload } from "@/core/config/ApiService";

class Boardcast {
  async ContentList(): Promise<any> {
    // const resp = await get("api/getContent");
    const resp = {
      data: [
        {
          date: "2016-05-03",
          subject: "หัวข้อ",
          description: "รายละเอียดของxxxxxxx",
          time: "2016-05-03 00:00:00",
        },
      ],
    };
    return resp.data;
  }
}
export default new Boardcast();
