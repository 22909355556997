/* eslint-disable @typescript-eslint/no-unused-vars */
import { post, get, postUpload } from "@/core/config/ApiService";

class Setting {
  async ContentList(): Promise<any> {
    // const resp = await get("api/getContent");
    const resp = {
      data: [],
    };
    return resp.data;
  }
}
export default new Setting();
