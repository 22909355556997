// export const dataURIToBlob = (dataURI: string): Blob | null => {
//   if (dataURI != null) {
//     const splitDataURI = dataURI.split(",");
//     const byteString =
//       splitDataURI[0].indexOf("base64") >= 0
//         ? atob(splitDataURI[1])
//         : decodeURI(splitDataURI[1]);
//     const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
//     const ia = new Uint8Array(byteString.length);
//     for (let i = 0; i < byteString.length; i++) {
//       ia[i] = byteString.charCodeAt(i);
//     }
//     return new Blob([ia], { type: mimeString });
//   } else {
//     return null;
//   }
// };

export const upload = (
  fileData: File | Blob,
  chat_id: string,
  caption: string
) => {
  const formData = new FormData();
  formData.append("photo", fileData);
  formData.append("chat_id", chat_id.toString());
  formData.append("caption", caption);

  return formData;
};
