import { App, Plugin, Ref, ref } from "vue";
import { ElLoading } from "element-plus";

export class LoadingService {
  task = 0;
  isLoading = false;
  loading: any;

  startLoading(): void {
    this.task++;
    this.isLoading = true;
    if (!this.loading) {
      this.loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    }
  }

  endLoading(): void {
    this.task--;
    if (this.task <= 0) {
      this.task = 0;
      this.isLoading = false;
      if (this.loading) {
        this.loading.close();
        this.loading = null;
      }
    }
  }
}

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $loading: LoadingService;
  }
}

const loadingInstance: Ref<LoadingService | null> = ref(null);

function createLoadingPlugin(): Plugin {
  return {
    install: (app: App, options: any = {}) => {
      if (!loadingInstance.value) {
        loadingInstance.value = new LoadingService();
      }
      app.config.globalProperties.$loading = loadingInstance.value;
      app.provide("$loading", loadingInstance.value);
    },
  };
}

export default createLoadingPlugin;
