/* eslint-disable @typescript-eslint/no-unused-vars */
import { post, get, postUpload } from "@/core/config/ApiService";
import { upload } from "@/core/services/Upload";

class Users {
  async UsersList(): Promise<any> {
    const resp = await get("api/getUsers");
    return resp.data;
  }

  async getChatHistoryForUser(
    telegramID: string,
    limit: number,
    offset: number
  ): Promise<any> {
    const url = `/api/getChatHistoryForUser?telegramID=${telegramID}&limit=${limit}&offset=${offset}`;
    const resp = await get(url);
    return resp.data;
  }

  async getProfile(
    telegramID: string,
    limit: number,
    offset: number
  ): Promise<any> {
    const url = `/api/getProfile?telegramID=${telegramID}`;
    const resp = await get(url);
    return resp.data;
  }
  
  async setNote(data: any): Promise<any> {
    const resp = await post("/api/setNote", data);
    return resp.data;
  }
  async setJob(data: any): Promise<any> {
    const resp = await post("/api/setJob", data);
    return resp.data;
  }
  

  
  async sendMessage(data: any): Promise<any> {
    const resp = await post("/api/sendMessage", data);
    return resp.data;
  }

  async sendPhoto(dataUri: any, chat_id: string, caption: string) {
    const file = await dataUri;
    const formData = await upload(file as File | Blob, chat_id, caption);
    return await postUpload("/api/sendPhoto", formData);
  }

  async setNickname(data: any): Promise<any> {
    const resp = await post("/api/setNickname", data);
    return resp.data;
  }

  async search(query: string): Promise<any> {
    const resp = await get(`/api/getUsers?search=${query}`);
    return resp.data;
  }

  async markAsRead(chat_id: string): Promise<any> {
    const resp = await get(`/api/markAsRead?chat_id=${chat_id}`);
    return resp.data;
  }
}
export default new Users();
