import { ActionContext } from "vuex";

import ApiServices from "@/core/services/Setting";

interface State {
  listContent: Array<any>;
}

const state: State = {
  listContent: [],
};

const getters = {
  getListContent: (state: State) => state.listContent,
};

const actions = {
  async fetch(context: ActionContext<State, any>, state: State) {
    try {
      const resp = await ApiServices.ContentList();
      context.commit("SET_LIST_CONTENT", resp);
    } catch (error: any) {
      console.log("error", error);
    }
  },
};

const mutations = {
  SET_LIST_CONTENT(state: State, data: Array<any>) {
    state.listContent = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
