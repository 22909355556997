import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Storage from "@/core/config/Storage";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "login-page",
    component: () => import("@/views/Auth/LoginPage.vue"),
  },
  {
    path: "/",
    name: "chat-page",
    component: () => import("../views/Chat.vue"),
  },
  {
    path: "/boardcast",
    name: "boardcast-page",
    component: () => import("../views/Boardcast.vue"),
  },
  {
    path: "/settings",
    name: "settings-page",
    component: () => import("../views/Settings.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to: any, from: any, next: any) => {
  const isAuthenticated = await Storage.checkToken();
  if (!["login-page"].includes(to.name) && !isAuthenticated) {
    await Storage.clearToken();
    next({ name: "login-page" });
  } else if (["login-page"].includes(to.name) && isAuthenticated) {
    next({ name: "chat-page" });
  } else {
    next();
  }
});

export default router;
